<template>
  <div>
    <custom-alert v-if="!refined.length">
      No records found.
    </custom-alert>

    <v-simple-table v-if="refined.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th>CONTRACT TITLE</th>
            <th>CONTRACT DATES</th>
            <th>DOWNLOAD</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="job in refined" :key="job.id">
            <td class="body-1">{{ job.title }}</td>
            <td class="body-1">{{ $startDate(job.contractDates) | downloadDate }} - {{ $endDate(job.contractDates) | downloadDate }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="downloadPDF(job)" v-on="on" icon>
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span>Download PDF</span>
              </v-tooltip>
              
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="downloadCsv(job)" v-on="on" icon>
                    <v-icon>mdi-contain</v-icon>
                  </v-btn>
                </template>
                <span>Download CSV</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import jsonexport from 'jsonexport'
import { jsPDF } from "jspdf"
import 'jspdf-autotable'
import moment from 'moment'

export default {
  props: {
    jobs: Array
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    refined: function () {
      let refined = this.jobs.filter(job => job.status == 'Open')

      return refined
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {

    /*------------------------------------------------------------------------------
     * DOWNLOAD CSV
     *----------------------------------------------------------------------------*/
    downloadCsv(job) {
      let data = []
      let applicants = this.$store.getters['apply/applicants'](job)

      if (applicants.length) {
        applicants.forEach(applicant => {
          data.push({
            'Name': this.fullName(applicant),
            'Years Experience': this.profile(applicant).teachingLvl,
            'Qualified level': this.profile(applicant).position.join(', '),
            'Skills': this.profile(applicant).skillset.join(', '),
            'Profile headline': this.profile(applicant).aboutMe,
            'Favourite': this.$store.getters['favorite/favoriteTeachers'](applicant.userid) ? 'Yes' : 'No',
            'Shortlisted': applicant.shortlisted ? 'Yes' : 'No',
            'Contract title': job.title,
            'Contract length': job.contractLength,
            'Contract dates': this.contracTdates(job),
            'Date created': moment().format('MM/DD/YYYY'),
          })
        })
  
        jsonexport(data, (err, csv) => {
          if (err) return console.error(err)
          else {
            let filename = `${job.title}_applicants.csv`
  
            var link = document.createElement("a")
            let csvContent = "data:text/csv;charset=utf-8," + csv
            var encodedUri = encodeURI(csvContent)
            link.setAttribute("href", encodedUri)
            link.setAttribute("download", filename)
            document.body.appendChild(link)
            link.click()
          }
        })
      }
    },

    /*------------------------------------------------------------------------------
     * DOWNLOAD PDF
     *----------------------------------------------------------------------------*/
    downloadPDF(job) {
      let applicants = this.$store.getters['apply/applicants'](job)
      let filename = `${job.title}_applicants.pdf`
      const doc = new jsPDF()
      let img = new Image
      let data = []

      img.src = `${window.location.origin}/images/pdf_logo.jpg`
      
      img.onload = () => {
        doc.addImage(img, 'JPEG', 10, 10, 35, 7)
        
        applicants.forEach((applicant) => {
          data.push([
            `Name: ${this.fullName(applicant)}\r\nYears Experience: ${this.profile(applicant).teachingLvl}\r\nQualified level: ${this.profile(applicant).position.join(', ')}\r\nSkills: ${this.profile(applicant).skillset.join(', ')}\r\n\r\n${this.profile(applicant).aboutMe}\r\nApplicant response:\r\n\r\n${this.getResponses(job, applicant)}`,
            this.$store.getters['favorite/favoriteTeachers'](applicant.userid) ? 'Yes' : 'No',
            applicant.shortlisted ? 'Yes' : 'No'
          ])
        })

        doc.autoTable({
          head:[['JOB APPLICANT SHORTLIST', '']],
          body: [
            [
              { content: `${job.title}\r\n${job.contractLength}\r\n${this.contracTdates(job)}`},
              { content: `Date Created: ${moment().format('MM/DD/YYYY')}`, styles: { halign: 'right' } }
            ]
          ],
          theme: 'plain',
          startY: 30,
        })
  
        doc.autoTable({
          head: [['APPLICANT PROFILES', 'FAVOURITE', 'SHORTLISTED']],
          body: data,
          theme: 'plain'
        })
  
        doc.save(`${filename}.pdf`)
      }
    },

    /*------------------------------------------------------------------------------
     * GET FULL NAME
     *----------------------------------------------------------------------------*/
    fullName(applicant) {
      return `${this.$store.getters['users/userData'](applicant.userid).firstName} ${this.$store.getters['users/userData'](applicant.userid).lastName}`
    },

    user(applicant) {
      return this.$store.getters['users/userData'](applicant.userid)
    },

    profile(applicant) {
      let user = this.$store.getters['users/userData'](applicant.userid)
      return this.$store.getters['profile/userProfile'](user.id)
    },

    contracTdates(job) {
      return `${this.$options.filters.downloadDate(this.$startDate(job.contractDates))} - ${this.$options.filters.downloadDate(this.$endDate(job.contractDates))}`
    },

    getResponses(job, applicant) {
      let responses = ''

      if (job.question1) responses += `${job.question1}\r\n${applicant.answers.q1}\r\n` 
      if (job.question2) responses += `${job.question2}\r\n${applicant.answers.q2}\r\n` 
      if (job.question3) responses += `${job.question3}\r\n${applicant.answers.q3}` 

      return responses
    }
  }
}
</script>