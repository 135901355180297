<template>
  <div class="flex-grow-1">
    <AdminTitle title="Reports"></AdminTitle>

    <div>
      <preloader v-if="status.gettingEmployerJobs" />
      
      <div class="mb-2">Choose your report type:</div>

      <div class="d-flex flex-wrap gap-12 align-center mb-5">
        <v-sheet max-width="320" class="flex-grow-1">
          <v-select
            v-model="view"
            :items="options"
            placeholder="Select View Type"
            hide-details
            full-width
            outlined
            dense
          ></v-select>
        </v-sheet>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
          width="100%"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-show="view == 'list'"
              v-model="dates"
              :rules="[rules.required]"
              placeholder="Date range"
              append-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              hide-details
              full-width
              readonly
              outlined
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            @change="getReport()"
            no-title
            range
          ></v-date-picker>
        </v-menu>

        <v-spacer></v-spacer>

        <div v-if="view == 'list'" class="d-flex gap-10 justify-end">
          <v-btn @click="downloadCsv()" color="primary">Download CSV</v-btn>
          <v-btn @click="downloadPdf()" color="primary">Download PDF</v-btn>
        </div>
      </div>

      <ConfirmedBookingsListView v-if="view == 'list' && !status.gettingEmployerJobs" ref="booking" :jobs="jobs" :dates="dates" :school="school" />
      <JobApplicationShortlist v-if="view == 'applicant' && !status.gettingEmployerJobs" :jobs="jobs" :dates="dates"/>
    </div>
  </div>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'
import ConfirmedBookingsListView from './ConfirmedBookingsListView'
import JobApplicationShortlist from './JobApplicationShortlist'

export default {
  metaInfo: {
    title: 'Reports'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      menu: null,
      dates: null,
      view: 'list',
      options: [
        { text: 'Confirmed Bookings', value: 'list' },
        { text: 'Job Applicant Shortlist', value: 'applicant' }
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    ConfirmedBookingsListView,
    JobApplicationShortlist
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.jobs.status,
      jobs: (state) => state.jobs.employerJobs,
      school: (state) => state.schools.school,
    }),
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('jobs', ['getEmployerJobs']),

    getReport() {
      this.$refs.menu.save(this.dates)
    },

    downloadCsv() {
      this.$refs.booking.downloadCsv()
    },
    
    downloadPdf() {
      this.$refs.booking.downloadPdf()
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getEmployerJobs()
    this.$store.dispatch('schools/getUserSchool')
    this.$store.dispatch('favorite/getTalentFavorites')
  }
}
</script>

