<template>
  <div>
    <custom-alert v-if="!refined.length">
      No records found.
    </custom-alert>

    <v-simple-table v-if="refined.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th>CONTRACT DATES</th>
            <th>TEACHER FIRST AND LAST NAME</th>
            <th>CONTRACT TITLE</th>
            <th>TYPE</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="job in refined" :key="job.id">
            <td class="body-1">{{ $startDate(job.contractDates) | downloadDate }} - {{ $endDate(job.contractDates) | downloadDate }}</td>
            <td class="body-1">{{ getFullName(job) }}</td>
            <td class="body-1">{{ job.title }}</td>
            <td class="body-1">{{ job.type }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import moment from 'moment'
import _orderBy from 'lodash/orderBy'
import jsonexport from 'jsonexport'
import { jsPDF } from "jspdf"
import 'jspdf-autotable'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    jobs: {
      type: Array,
      required: true
    },
    dates: Array,
    school: Object,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    refined: function () {
      let refined = this.jobs.filter(job => {
        return this.$store.getters['apply/getHired'](job.id)
      })

      if (this.dates && this.dates.length) {
        refined = refined.filter(job => {
          let start = this.$startDate(job.contractDates)
          let rangeStart = moment(this.dates[0]).valueOf()
          let rangeEnd = moment(this.dates[1]).valueOf()
          
          return start >= rangeStart && start <= rangeEnd
        })
      }

      refined = _orderBy(refined, (job) => {
        return this.$startDate(job.contractDates)
      }, 'asc')

      return refined
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getFullName(job) {
      let user = this.$store.getters['users/userData'](this.$store.getters['apply/getHired'](job.id).userid)
      return user ? `${ user.firstName } ${user.lastName}` : {}
    },
  
    /*------------------------------------------------------------------------------
     * DOWNLOAD CSV
     *----------------------------------------------------------------------------*/
    downloadCsv() {
      let data = []

      this.refined.forEach(job => {
        data.push({
          'CONTRACT DATES': `${ this.$options.filters.downloadDate(this.$startDate(job.contractDates)) } - ${ this.$options.filters.downloadDate(this.$endDate(job.contractDates)) }`,
          'TEACHER FIRST AND LAST NAME': `${ this.getFullName(job) }`,
          'CONTRACT TITLE': job.title,
          'TYPE': job.type
        })
      })

      jsonexport(data, (err, csv) => {
        if (err) return console.error(err)
        else {
          let filename = this.dates ? `report_${moment(this.dates[0]).format('DDMMYYYY')}_to_${moment(this.dates[1]).format('DDMMYYYY')}.csv` : 'report.csv'

          var link = document.createElement("a")
          let csvContent = "data:text/csv;charset=utf-8," + csv
          var encodedUri = encodeURI(csvContent)
          link.setAttribute("href", encodedUri)
          link.setAttribute("download", filename)
          document.body.appendChild(link)
          link.click()
        }
      })
    },

    /*------------------------------------------------------------------------------
     * DOWNLOAD PDF
     *----------------------------------------------------------------------------*/
    downloadPdf() {
      const doc = new jsPDF()
      let filename = this.dates ? `report_${moment(this.dates[0]).format('DDMMYYYY')}_to_${moment(this.dates[1]).format('DDMMYYYY')}` : 'report'
      let data = []
      let img = new Image
      
      img.src = `${window.location.origin}/images/pdf_logo.jpg`
      
      img.onload = () => {
        this.refined.forEach(job => {
          data.push([
            `${ this.$options.filters.downloadDate(this.$startDate(job.contractDates)) } - ${ this.$options.filters.downloadDate(this.$endDate(job.contractDates)) }`,
            this.getFullName(job),
            job.title,
            job.type
          ])
        })
        
       doc.addImage(img, 'JPEG', 10, 15, 35, 7)

        doc.autoTable({
          body: [
            { content: `BOOKINGS SCHEDULE: ${this.contractDates()}`},
            { content: `${this.school.name}`, styles: { fontStyle: 'bold' }}
          ],
          theme: 'plain',
          startY: 25,
        })
  
        doc.autoTable({
          head: [['CONTRACT DATES', 'TEACHER FIRST AND LAST NAME', 'CONTRACT TITLE', 'TYPE']],
          body: data,
          theme: 'plain'
        })
  
        doc.save(`${filename}.pdf`)
      }

    },

    contractDates() {
      return this.dates ? `${moment(this.dates[0]).format('DD/MM/YYYY')} - ${moment(this.dates[1]).format('DD/MM/YYYY')}` : ''
    }
  }
}
</script>